import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../../images/dodion-b-512.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

class Navbar extends Component {
  state = {
    navbarOpen: false,
    css: "collapse navbar-collapse",
    links: [
      {
        id: 1,
        path: "/",
        text: "Home",
      },
      {
        id: 2,
        path: "/about",
        text: "About",
      },
    ],
  }
  navbarHandler = () => {
    console.log("Navbar handler...")
    this.state.navbarOpen ? this.setState({
      navbarOpen: false,
      css: "collapse navbar-collapse",
    }) : this.setState({ navbarOpen: true, css: "collapse navbar-collapse show" })
  }

  render() {

    return (
      <nav className="navbar navbar-expand-sm bg-light navbar-light fixed-top">
        <div className="container">
          <AnchorLink to="/#home" className="navbar-brand">
            <img src={logo} width="48px" alt='Dodion logo'/>
          </AnchorLink>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.navbarHandler}>
            <span className="navbar-toggler-icon"/>
          </button>
          <div className={this.state.css}>
            <ul className="navbar-nav mx-auto">
              <AnchorLink to="/#home" title="Home" className="nav-link text-capitalize">
                <h4>Home</h4>
              </AnchorLink>
              <AnchorLink to="/#our-story" title="Our story" className="nav-link text-capitalize">
                <h4>Our story</h4>
              </AnchorLink>
              <AnchorLink to="/#case-studies" title="Case studies" className="nav-link text-capitalize">
                <h4>Case studies</h4>
              </AnchorLink>
              <AnchorLink to="/#contact-us" title="Contact us" className="nav-link text-capitalize">
                <h4>Contact us</h4>
              </AnchorLink>
            </ul>
          </div>
        </div>
      </nav>
    )

    // return (
    //   <nav className="navbar navbar-expand-sm bg-light navbar-light">
    //     <Link to="/" className="navbar-brand">
    //       <img src={logo} width="48px" alt='Dodion logo'/>
    //     </Link>
    //     <button
    //       className="navbar-toggler"
    //       type="button"
    //       onClick={this.navbarHandler}>
    //       <span className="navbar-toggler-icon"/>
    //     </button>
    //     <div className={this.state.css}>
    //       <ul className="navbar-nav mx-auto">
    //         {
    //           this.state.links.map(link => {
    //             return (
    //               <li key={link.id} className="nav-item">
    //                 <Link to={link.path} className="nav-link text-capitalize">
    //                   <h3>{link.text}</h3>
    //                 </Link>
    //               </li>
    //             )
    //           })
    //         }
    //       </ul>
    //     </div>
    //   </nav>
    // )
  }
}

export default Navbar
