import React from "react"
import fb_logo from "../../images/facebook-square-brands.svg"
import li_logo from "../../images/linkedin-brands.svg"
import twitter_logo from "../../images/twitter-square-brands.svg"

function Footer(props) {
  return (
    <footer className="footer py-3">

            <span>
              All rights reserved &copy;{new Date().getFullYear().toString()} Dodion Limited
            </span>
      {/*<span className="social-media-icons">*/}
      {/*        <span>*/}
      {/*          <a href="https://www.facebook.com/Dodion-115631856748967">*/}
      {/*            <img src={fb_logo} alt="facebook logo"/>*/}
      {/*          </a>*/}
      {/*        </span>*/}
      {/*        <span>*/}
      {/*          <a href="https://www.linkedin.com/company/19630368">*/}
      {/*            <img src={li_logo} alt="linked in logo"/>*/}
      {/*          </a>*/}
      {/*        </span>*/}
      {/*        <span>*/}
      {/*          <a href="https://twitter.com/DodionLimited">*/}
      {/*            <img src={twitter_logo} alt="twitter logo"/>*/}
      {/*          </a>*/}
      {/*        </span>*/}
      {/*      </span>*/}

    </footer>
  )
}

export default Footer
